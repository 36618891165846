import React, { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { AnimationMixer, LoopRepeat, LoopOnce } from 'three';
import { useFrame } from '@react-three/fiber';

const AvatarModel = ({ isSpeaking }) => {
  const { scene, animations } = useGLTF('/Blu - Anime Girl Character.glb');
  const ref = useRef();
  const mixer = useRef();
  const actions = useRef({});

  // Define the time range for the lip movement section
  const lipSyncStart = 4.0; // Start time in seconds
  const lipSyncEnd = 5.2; // End time in seconds

  // Define the time range for the idle animation section
  const idleStart = 5.21; // Start time in seconds
  const idleEnd = 5.21; // End time in seconds

  useEffect(() => {
    if (scene && animations.length > 0) {
      mixer.current = new AnimationMixer(scene);

      // Create actions for each animation
      animations.forEach((clip) => {
        actions.current[clip.name] = mixer.current.clipAction(clip);
        actions.current[clip.name].setLoop(LoopRepeat);
        console.log("Playing animation", clip.name);
      });

      // Play the "Idle" animation if it exists
      if (actions.current['Idle']) {
        actions.current['Idle'].play();
      }
    }

    return () => {
      // Cleanup the mixer
      mixer.current?.stopAllAction();
      mixer.current = null;
    };
  }, [scene, animations]);

  useEffect(() => {
    if (mixer.current && actions.current['Idle']) {
      if (isSpeaking) {
        // Set the action to loop over the lip sync section
        actions.current['Idle'].setLoop(LoopRepeat, 1);
        actions.current['Idle'].time = lipSyncStart;
        actions.current['Idle'].clampWhenFinished = true;
        actions.current['Idle'].play();
      } else {
        // Resume the idle animation from the specified range
        actions.current['Idle'].setLoop(LoopRepeat);
        actions.current['Idle'].time = idleStart;
        actions.current['Idle'].play();
      }
    }
  }, [isSpeaking]);

  useFrame((state, delta) => {
    if (mixer.current) {
      mixer.current.update(delta);

      // Restart the lip sync section if it ends while speaking
      if (isSpeaking && actions.current['Idle'] && actions.current['Idle'].time > lipSyncEnd) {
        actions.current['Idle'].time = lipSyncStart;
      }

      // Restart the idle animation if it ends while not speaking
      if (!isSpeaking && actions.current['Idle'] && actions.current['Idle'].time > idleEnd) {
        actions.current['Idle'].time = idleStart;
      }
    }
  });

  return (
    <primitive 
      object={scene} 
      ref={ref} 
      position={[0, -4.5, 0]} // Adjust position
      scale={[3,3,3]} // Adjust size
      rotation={[0.3, Math.PI / -25, 0]} // Adjust rotation
    />
  );
};

export default AvatarModel;
