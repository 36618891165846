import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const modelOptions = {
    ttsModels: ["Default","Open AI TTS", "AWS Polly", "Google TTS", "Huggingface"],
    sttModels: ["Whisper", "Google STT"],
    llmModels: ["GPT-4o", "GPT-4o mini", "GPT-4 Turbo", "Claude 3.5 Sonnet", "Claude Opus"]
};

const languageOptions = ["English", "Spanish", "French", "German", "Chinese", "Hebrew"];
const topicsConfig = ["maths", "history", "science", "english"];

const Settings = ({ userData }) => {
    const [settings, setSettings] = useState({
        ttsModel: 'Open AI TTS',
        sttModel: 'Whisper',
        llmModel: 'GPT-4o',
        email: userData ? userData.email : '',
        name: userData ? userData.student : '',
        defaultLanguage: 'English',
        topics: {
            maths: { model: "GPT-4o", prompt: "Enter your math problem", subTopics: {} },
            history: { model: "GPT-4o", prompt: "Discuss historical event", subTopics: {} },
            science: { model: "GPT-4o", prompt: "Explain scientific concept", subTopics: {} },
            english: { model: "GPT-4o", prompt: "Analyze this text", subTopics: {} }
        }
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Fetch user settings from DB when component mounts
    useEffect(() => {
        if (userData && userData.email) {
            setLoading(true);
            axios.get(`https://api.best-ai-chatbots.com/api/settings?email=${userData.email}`)
                .then((response) => {
                    const fetchedSettings = response.data.settings || {}; // Use empty object as fallback
                    const updatedSettings = {
                        ...settings,
                        ...fetchedSettings,
                        topics: {
                            ...settings.topics, // Preserve initial topic structure
                            ...fetchedSettings.topics // Override if present in fetched settings
                        }
                    };
                    setSettings(updatedSettings);
                    toast.success("Settings loaded successfully!", { autoClose: 15000 });
                })
                .catch(() => {
                    setError("Failed to load settings. Please try again.");
                    toast.error("Failed to load settings.", { autoClose: 15000 });
                })
                .finally(() => setLoading(false));
        }
    }, [userData]);

    const handleModelChange = (modelType, value) => {
        setSettings(prev => ({ ...prev, [modelType]: value }));
    };

    const handleTopicConfigChange = (topic, field, value) => {
        if (!settings.topics[topic]) {
            toast.error(`Topic ${topic} is not available`, { autoClose: 15000 });
            return;
        }
        const updatedTopic = {
            ...settings.topics[topic],
            [field]: value
        };
        setSettings(prev => ({
            ...prev,
            topics: {
                ...prev.topics,
                [topic]: updatedTopic
            }
        }));
    };

    const addSubTopic = (topic) => {
        if (!settings.topics[topic]) {
            toast.error(`Topic ${topic} is not available`, { autoClose: 15000 });
            return;
        }
        const newSubTopicName = prompt("Enter the name for the new sub-topic:");
        if (!newSubTopicName) return; // Cancel if no input

        const newSubTopic = { model: settings.llmModel, prompt: "" };
        const updatedSubTopics = {
            ...settings.topics[topic].subTopics,
            [newSubTopicName]: newSubTopic
        };

        setSettings(prev => ({
            ...prev,
            topics: {
                ...prev.topics,
                [topic]: { ...prev.topics[topic], subTopics: updatedSubTopics }
            }
        }));
    };

    const deleteSubTopic = (topic, subTopicName) => {
        if (!settings.topics[topic]?.subTopics[subTopicName]) {
            toast.error(`Sub-topic ${subTopicName} does not exist`, { autoClose: 15000 });
            return;
        }
        
        const updatedSubTopics = { ...settings.topics[topic].subTopics };
        delete updatedSubTopics[subTopicName]; // Remove the subtopic

        setSettings(prev => ({
            ...prev,
            topics: {
                ...prev.topics,
                [topic]: { ...prev.topics[topic], subTopics: updatedSubTopics }
            }
        }));

        toast.success(`Sub-topic ${subTopicName} deleted successfully!`, { autoClose: 15000 });
    };

    // Update settings in the DB without page refresh
    const updateSettings = (e) => {
        e.preventDefault(); // Prevent the form from submitting and refreshing the page
        setLoading(true);
        axios.post('https://api.best-ai-chatbots.com/api/update_settings', { email: settings.email, settings })
            .then(() => {
                toast.success("Settings updated successfully!", { autoClose: 15000 });
            })
            .catch(() => {
                setError("Failed to update settings.");
                toast.error("Failed to update settings.", { autoClose: 15000 });
            })
            .finally(() => setLoading(false));
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="settings-container">
            <ToastContainer />
            <div className="column settings-column">
                <h2>Settings</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={updateSettings}>
                    <label>Email: {settings.email}</label><br />
                    <label>Name: {settings.name}</label><br />
                    {['tts', 'stt', 'llm'].map(field => (
                        <fieldset key={field}>
                            <legend>{field.toUpperCase()} Model</legend>
                            <select
                                name={field + "Model"}
                                value={settings[field + 'Model']}
                                onChange={e => handleModelChange(field + 'Model', e.target.value)}>
                                {modelOptions[field + 'Models'].map(model => (
                                    <option key={model} value={model}>{model}</option>
                                ))}
                            </select>
                        </fieldset>
                    ))}

                    <label>Default Language:</label>
                    <div className="lang_container">

                    <select
    id="defaultLanguage"
    value={settings.defaultLanguage}
    onChange={e => setSettings(prev => ({ ...prev, defaultLanguage: e.target.value }))}
    className="select-small"
>
    {languageOptions.map(lang => (
        <option key={lang} value={lang}>{lang}</option>
    ))}
</select>
<br/><hr/>
<button type="submit" className="button-small">Update Settings</button>

</div>
                   
                    
                </form>
            </div>
        </div>
    );
};

export default Settings;
