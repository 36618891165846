import React, { useState } from 'react';

const UserForm = ({ onSubmit }) => {
  const [student, setStudent] = useState('Jane Doe');
  const [email, setEmail] = useState('Jane.doe@gmail.com');
  const [age, setAge] = useState(12);

  const handleSubmit = (subject) => {
    onSubmit({ student, email, age, subject });
  };

  return (
    <div className="user-form-container">
      <form className="user-form">
        <h2 className="form-title">Welcome to Our Class</h2>
        <div className="form-group">
          <label>Name:</label>
          <input type="text" value={student} onChange={(e) => setStudent(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Age:</label>
          <input type="number" value={age} onChange={(e) => setAge(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Subject:</label>
          <div className="subject-buttons">
            <button type="button" onClick={() => handleSubmit('Math')}>
              <img src="maths.jpg" alt="Math" />
              Math
            </button>
            <button type="button" onClick={() => handleSubmit('Science')}>
              <img src="science.jpg" alt="Science" />
              Science
            </button>
            <button type="button" onClick={() => handleSubmit('History')}>
              <img src="history.jpg" alt="History" />
              History
            </button>
            <button type="button" onClick={() => handleSubmit('English')}>
              <img src="english.jpg" alt="English" />
              English
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
